import loginCard from './b4-login-card.vue';
import loginMenu from './b4-login-menu.vue';
import loginModal from './b4-login-modal.vue';
import login from './b4-login.vue';
var loginComponents = {
    loginCard: loginCard,
    loginMenu: loginMenu,
    loginModal: loginModal,
    login: login
};
export { loginComponents };
