import api from '../../api/b4';
export default {
    name: "b4-login",
    computed: {
        canLogIn: function () {
            return this.credentials.userName
                && this.credentials.password;
        }
    },
    watch: {
        "menu": function (val) {
            if (!val) {
                this.badCredentials = false;
                this.processing = false;
            }
        }
    },
    methods: {
        logIn: function () {
            var _this = this;
            if (this.processing)
                return;
            this.processing = true;
            this.badCredentials = false;
            var self = this;
            api.authenticate(this.credentials).then(function (authResponse) {
                api.externalLogin(authResponse);
            }).catch(function (error) {
                console.log(error);
                _this.badCredentials = true;
                _this.processing = false;
                self.cancel();
            });
        }
    }
};
