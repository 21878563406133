import 'babel-polyfill';
import Vue from 'vue';
import Vuetify from 'vuetify';
import { components } from './components';
import { PageLayout } from './layout';
for (var k in components) {
    if (!!components[k].extendOptions && !!components[k].extendOptions.name) {
        Vue.component(components[k].extendOptions.name, components[k]);
    }
    else {
        Vue.component(components[k].name, components[k]);
    }
}
Vue.use(Vuetify);
new Vue({}).$mount('#app');
var init = new PageLayout();
init.run();
