var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hideForm
    ? _c(
        "div",
        { staticClass: "cont_general bg_green", attrs: { id: "contact" } },
        [
          _c("div", { staticClass: "center" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "layout-grid" }, [
              _c("form", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("v-text-field", {
                      attrs: { dark: "", label: "Name", outline: "" },
                      model: {
                        value: _vm.contactInfo.name,
                        callback: function($$v) {
                          _vm.$set(_vm.contactInfo, "name", $$v)
                        },
                        expression: "contactInfo.name"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("v-text-field", {
                      attrs: {
                        dark: "",
                        label: "Email",
                        type: "email",
                        outline: ""
                      },
                      model: {
                        value: _vm.contactInfo.email,
                        callback: function($$v) {
                          _vm.$set(_vm.contactInfo, "email", $$v)
                        },
                        expression: "contactInfo.email"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("v-text-field", {
                      attrs: { dark: "", label: "Company", outline: "" },
                      model: {
                        value: _vm.contactInfo.company,
                        callback: function($$v) {
                          _vm.$set(_vm.contactInfo, "company", $$v)
                        },
                        expression: "contactInfo.company"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("v-text-field", {
                      attrs: { dark: "", label: "Phone", outline: "" },
                      model: {
                        value: _vm.contactInfo.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.contactInfo, "phone", $$v)
                        },
                        expression: "contactInfo.phone"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-9" },
                  [
                    _c("v-text-field", {
                      attrs: { dark: "", label: "Message", outline: "" },
                      model: {
                        value: _vm.contactInfo.message,
                        callback: function($$v) {
                          _vm.$set(_vm.contactInfo, "message", $$v)
                        },
                        expression: "contactInfo.message"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-3 custom-btn-form" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btns btns-grayBlue",
                        attrs: { disabled: !_vm.canSend, type: "button" },
                        on: { click: _vm.requestDemo }
                      },
                      [_vm._v("Set up a demo")]
                    )
                  ],
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: {
                id: "notification",
                width: "500",
                dark: "",
                persistent: ""
              },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v("Thank you for contacting us")
                  ]),
                  _vm._v(" "),
                  _c("v-card-text", { staticClass: "subheading" }, [
                    _vm._v("Someone will be in touch shortly")
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "v-btn-green",
                          attrs: { loading: _vm.sending, flat: "flat" },
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                              _vm.hideForm = true
                            }
                          }
                        },
                        [_vm._v("\n                    Ok\n                ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _vm._v("This is what I’ve been looking for! "),
      _c("br"),
      _vm._v("Let’s set up a demo.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }