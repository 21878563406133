var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            bottom: "",
            "offset-y": "",
            "close-on-content-click": false,
            "max-width": "300",
            "content-class": "bg-b4-login",
            attach: "root-menu"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g({ staticClass: "log-in", attrs: { dark: "" } }, on),
                    [_vm._v("Log in")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _vm._v(" "),
          _vm.menu
            ? _c("b4-login-card", {
                on: {
                  cancel: function($event) {
                    _vm.menu = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "root-menu",
        staticStyle: { position: "relative" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }