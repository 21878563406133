import api from '../../api/b4';
export default {
    name: "b4-login-card",
    data: function () {
        return {
            processing: false,
            credentials: {
                userName: null,
                password: null,
                provider: "credentials",
                useTokenCookie: true
            },
            badCredentials: false,
            passwordVisible: false,
            rules: {
                required: function (value) { return !!value || 'Required.'; }
            }
        };
    },
    computed: {
        canLogIn: function () {
            return this.credentials.userName
                && this.credentials.password;
        }
    },
    methods: {
        logIn: function () {
            var _this = this;
            if (this.processing)
                return;
            this.processing = true;
            this.badCredentials = false;
            var self = this;
            api.authenticate(this.credentials).then(function (response) {
                api.externalLogin(response);
            }).catch(function (error) {
                _this.badCredentials = true;
                _this.processing = false;
                self.cancel();
            });
        },
        cancel: function () {
            this.credentials.userName = null;
            this.credentials.password = null;
        }
    }
};
