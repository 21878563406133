var configs = {
    base: {},
    'env': {
        'local': {
            'logging': {
                'level': 3
            },
            'api': {
                'base': 'http://localhost:50441/',
                'url': '/',
                'externalUrl': 'http://localhost:51550/'
            }
        },
        'development': {
            'logging': {
                'level': 3
            },
            'api': {
                'url': '/',
                'externalUrl': 'https://pmg-app-beyond4-web-dev.azurewebsites.net/'
            }
        },
        'qa': {
            'logging': {
                'level': 3
            },
            'api': {
                'url': '/',
                'externalUrl': 'https://pmg-app-beyond4-web-qa.azurewebsites.net/'
            }
        },
        'production': {
            'logging': {
                'level': 0
            },
            'api': {
                'url': '/',
                'externalUrl': 'https://app.beyond4app.com/'
            }
        }
    }
};
var ConfigHelper = (function () {
    function ConfigHelper(data) {
        this.isExternal = false;
        this.configData = data;
    }
    ConfigHelper.prototype.setLib = function (isExternal) {
        this.isExternal = isExternal;
    };
    ConfigHelper.prototype.setAsLocalScope = function () {
        this.isExternal = false;
    };
    ConfigHelper.prototype.getApiUrl = function (e) {
        if (this.isExternal) {
            return this.settings(e).api.externalUrl;
        }
        return this.settings(e).api.url;
    };
    ConfigHelper.prototype.settings = function (e) {
        return this.configData.env[e];
    };
    ConfigHelper.prototype.base = function () {
        return this.configData.base;
    };
    ConfigHelper.prototype.addEnvironment = function (obj) {
        Object.assign(this.configData.env, obj);
    };
    return ConfigHelper;
}());
var configHelper = new ConfigHelper(configs);
export default configHelper;
