var Slideshow = (function () {
    function Slideshow() {
    }
    Slideshow.pageHasSlideshow = function () {
        return $('.cycle-slideshow').length > 0;
    };
    Slideshow.run = function () {
        if (Slideshow.pageHasSlideshow()) {
            Slideshow.initialize();
        }
    };
    Slideshow.initialize = function () {
        var slideshow = $('.cycle-slideshow');
        var maxSlides = slideshow.data('cycle.opts').slideCount;
        slideshow.on({
            'cycle-update-view': function (event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag) {
                $(".controls").show();
                Slideshow.UpdateTitles(slideshow, maxSlides);
            },
        });
        slideshow.on('cycle-before', function (event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag) {
            $(outgoingSlideEl).removeClass("show_headline");
        });
        slideshow.on('cycle-after', function (event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag) {
            $(incomingSlideEl).addClass("show_headline");
        });
        setTimeout(function () {
            $(".main_carousel li:nth-child(1)").addClass("show_headline");
        }, 1000);
        Slideshow.UpdateTitles(slideshow, maxSlides);
    };
    Slideshow.UpdateTitles = function (slideshow, maxSlides) {
        var currentSlide, activeSlide, activeTitle, nextTitle, prevTitle;
        currentSlide = slideshow.data('cycle.opts').currSlide;
        activeSlide = $(".cycle-slide-active");
        activeTitle = activeSlide.data('title');
        if (currentSlide == 0) {
            nextTitle = activeSlide.next().data('title');
            prevTitle = $(".cycle-slide").eq(maxSlides - 1).data('title');
        }
        else if (currentSlide == maxSlides - 1) {
            prevTitle = activeSlide.prev().data('title');
            nextTitle = $(".cycle-slide").eq(0).data('title');
        }
        else {
            nextTitle = activeSlide.next().data('title');
            prevTitle = activeSlide.prev().data('title');
        }
        if (maxSlides >= 2) {
            $('.prev span').html(prevTitle ? prevTitle : 'Prev');
            $('.next span').html(nextTitle ? nextTitle : 'Next');
            $(".controls a").addClass("show");
            $(".cycle-pager").show();
        }
        else {
            $(".controls a").removeClass("show");
            $(".cycle-pager").hide();
        }
    };
    return Slideshow;
}());
export { Slideshow };
