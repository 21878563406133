import * as $ from 'jquery';
import { Slideshow } from './slideshow';
import { NavigationMenu } from './navigation-menu';
import { Slick } from './slick';
import { ScrollTop } from './scroll-top';
var PageLayout = (function () {
    function PageLayout() {
    }
    PageLayout.prototype.run = function () {
        $(function () {
            Slideshow.run();
            Slick.run();
            NavigationMenu.run();
            ScrollTop.run();
        });
    };
    return PageLayout;
}());
export { PageLayout };
