import * as dtos from '../_common/dtos';
import config from '../config';
import service from '../service';
export default {
    authenticate: function (credentials) {
        var request = new dtos.Authenticate();
        request = Object.assign(request, credentials);
        return service.getExternalClient().post(request);
    },
    externalLogin: function (sessionInfo) {
        try {
            var request = new dtos.RedirectLogin();
            request.token = sessionInfo.bearerToken;
            request.urlRedirect = new URL(config.configData.env[ENV].api.externalUrl + "site").href;
            return service.getClient().get(request).then(function (response) {
                window.location.href = response.absoluteUrl;
            });
        }
        catch (error) {
            throw error;
        }
    },
    requestDemo: function (userInfo) {
        var request = new dtos.SetUpDemoRequest();
        request = Object.assign(request, userInfo);
        return service.getClient().post(request);
    }
};
