import { JsonServiceClient } from '@servicestack/client';
import config from './config';
var Service = (function () {
    function Service() {
    }
    Object.defineProperty(Service, "instance", {
        get: function () {
            return this._instance || (this._instance = new this());
        },
        enumerable: true,
        configurable: true
    });
    Service.prototype.getClient = function () {
        return this._jsonServiceClient || (this._jsonServiceClient = new JsonServiceClient(config.configData.env[ENV].api.url));
    };
    Service.prototype.getExternalClient = function () {
        return this._jsonExternalServiceClient || (this._jsonExternalServiceClient = new JsonServiceClient(config.configData.env[ENV].api.externalUrl));
        ;
    };
    return Service;
}());
export default Service.instance;
