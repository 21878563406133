import * as $ from 'jquery';
import { Slick } from './slick';
var NavigationMenu = (function () {
    function NavigationMenu() {
    }
    NavigationMenu.pageHasNavigationMenu = function () {
        return $('.main_nav').length > 0;
    };
    NavigationMenu.run = function () {
        if (NavigationMenu.pageHasNavigationMenu()) {
            NavigationMenu.initialize();
        }
    };
    NavigationMenu.initialize = function () {
        NavigationMenu.socialMobile();
        NavigationMenu.nav();
        NavigationMenu.navBars();
        NavigationMenu.openFaqs();
        NavigationMenu.initScroll();
        NavigationMenu.lavazi();
        NavigationMenu.actionNav();
        $(window).resize(function () {
            NavigationMenu.nav();
            NavigationMenu.lavazi();
        });
    };
    NavigationMenu.nav = function () {
        if (NavigationMenu.isWidthForDesktop()) {
            $("body").removeClass("mobile");
            $("body").addClass("desktop");
            $(".desktop .main_nav").on("mouseenter", function () {
                $(".cont_main_nav").addClass("trans_bg open-header");
            });
            $(".desktop .cont_main_nav").on("mouseleave", function () {
                var $this = $(this);
                $this.removeClass("open-header");
                setTimeout(function () {
                    $this.removeClass("trans_bg");
                }, 500);
            });
        }
        else {
            $("body").removeClass("desktop");
            $("body").addClass("mobile");
        }
    };
    NavigationMenu.isWidthForDesktop = function () {
        return $(window).width() > 850;
    };
    NavigationMenu.actionNav = function () {
        $(".nav_main li a, .sign-up-for-a-demo, .sign-up, .features-foot a, .nav_foot a").on("click", function (e) {
            if (!!e.target.hash)
                e.preventDefault();
            var isMobile = $("body").hasClass("mobile");
            var href = $(this).attr("href");
            var offsetTop = $(href).offset();
            var scroll_top = $(window).scrollTop();
            var hBarTop = isMobile ? 50 : 70;
            var posTop = (href == "#" || href == "" || $(href).length == 0) ? scroll_top : offsetTop.top - hBarTop;
            var isParentNav = !!$(this).closest(".nav_main").length;
            var isFeaturesFoot = !!$(this).closest(".features-foot").length;
            var gotoPosition = $(this).attr("data-go-position");
            var goIndex = !!gotoPosition ? gotoPosition : 0;
            if (isMobile && isParentNav) {
                $(".bars").click();
                setTimeout(function () {
                    $("html, body").animate({ scrollTop: posTop }, 900);
                }, 300);
            }
            else {
                if (isFeaturesFoot)
                    Slick.slickGoToIndex(goIndex);
                $("html, body").animate({ scrollTop: posTop }, 900);
                ;
            }
        });
    };
    NavigationMenu.navBars = function () {
        var statuss = true;
        $(".bars").on("click", function () {
            if (statuss) {
                $(this).add(".main_nav, .overlay_body, .subscribe_mobile").addClass("open");
                $(".mobile").addClass("bars_open");
                statuss = false;
            }
            else {
                $(this).add(".main_nav, .overlay_body, .subscribe_mobile").removeClass("open");
                $(".mobile").removeClass("bars_open");
                statuss = true;
            }
        });
    };
    NavigationMenu.socialMobile = function () {
        var s_head = $(".sm_head").children().clone();
        var m_phone = $(".mail_phone").children().clone();
        var i_search = $(".icon_search").children().clone();
        $(".sm-mobile").html(s_head);
        $(".dir_mobile").html(m_phone);
        $(".search_mobile").html(i_search);
        $(".nav_main >ul >li").each(function () {
            var uls = $(this).children("ul");
            uls.parent().addClass("pluss");
            uls.parent().append("<span class='iconss'><i class='fa fa-plus'></i><i class='fa fa-minus'></i></span>");
        });
        $(".pluss").on("click", function () {
            $(this).toggleClass("open");
        });
    };
    NavigationMenu.initScroll = function () {
        $(window).on("scroll", function () {
            NavigationMenu.verifyScroll();
        });
        $(window).resize(function () {
            setTimeout(function () {
                NavigationMenu.verifyScroll();
            }, 200);
        });
        setTimeout(function () {
            NavigationMenu.verifyScroll();
        }, 1700);
    };
    NavigationMenu.verifyScroll = function () {
        var navTop = $(".pos_menu").offset().top;
        var scroll_top = $(window).scrollTop();
        if (scroll_top > navTop) {
            $(".cont_main_nav").addClass("pos_initial show_scroll nav_mobile");
            $(".cont_main_nav").removeClass("prev_pos");
        }
        else {
            $(".cont_main_nav").removeClass("nav_mobile");
            if (scroll_top > 90) {
                $(".cont_main_nav").addClass("pos_initial show_scroll prev_pos");
            }
            else {
                $(".cont_main_nav").removeClass("show_scroll pos_initial prev_pos");
                if (scroll_top < 50) {
                }
            }
        }
    };
    NavigationMenu.lavazi = function () {
        if (NavigationMenu.isWidthForDesktop) {
            $('.main_nav .nav_main >ul').lavazi({
                background: "#91c66b",
                transitionTime: 500,
                theme: 'simple',
                height: 4,
                mode: 'belowBar',
                easings: 'easeOut'
            });
        }
        $(".main_nav .nav_main >ul >li").hover(function () {
            var l = $(this).position();
            $(".bar_bottom").css("left", (l.left + 16) + "px");
        });
    };
    NavigationMenu.openFaqs = function () {
        var body = document.querySelector("body");
        var faqs = document.querySelectorAll(".faqs");
        var overlay_menu = document.querySelector(".overlay_menu");
        var close_overlay_menu = document.querySelector(".close-overlay_menu");
        var wrapper_faqs = document.querySelector(".wrapper-faqs");
        faqs.forEach(function (faq) {
            faq.addEventListener("click", function (e) {
                e.preventDefault();
                overlay_menu.classList.add("open");
                wrapper_faqs.classList.add("open");
                body.classList.add("overflow");
            });
        });
        close_overlay_menu.addEventListener("click", function () {
            overlay_menu.classList.remove("open");
            wrapper_faqs.classList.remove("open");
            body.classList.remove("overflow");
            body.focus();
        });
    };
    return NavigationMenu;
}());
export { NavigationMenu };
