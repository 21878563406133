var ScrollTop = (function () {
    function ScrollTop() {
    }
    ScrollTop.pageHasSlick = function () {
        return $('.back_top').length > 0;
    };
    ScrollTop.run = function () {
        if (ScrollTop.pageHasSlick()) {
            ScrollTop.initialize();
        }
    };
    ScrollTop.initialize = function () {
        ScrollTop.backTop();
    };
    ScrollTop.backTop = function () {
        $(".back_top").on("click", function () {
            $("html, body").animate({
                scrollTop: 0
            }, 900);
        });
        $(window).on("scroll", function () {
            ScrollTop.verifyScroll();
        });
        ScrollTop.verifyScroll();
    };
    ScrollTop.verifyScroll = function () {
        var scrollTrigger = 200;
        var scroll_top = $(window).scrollTop();
        if (scroll_top > scrollTrigger) {
            $(".back_top").addClass("show");
        }
        else {
            $(".back_top").removeClass("show");
        }
    };
    return ScrollTop;
}());
export { ScrollTop };
