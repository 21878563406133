var ResponseError = (function () {
    function ResponseError() {
    }
    return ResponseError;
}());
export { ResponseError };
var ResponseStatus = (function () {
    function ResponseStatus() {
    }
    return ResponseStatus;
}());
export { ResponseStatus };
var AuthenticateResponse = (function () {
    function AuthenticateResponse() {
    }
    return AuthenticateResponse;
}());
export { AuthenticateResponse };
var Authenticate = (function () {
    function Authenticate() {
    }
    Authenticate.prototype.createResponse = function () { return new AuthenticateResponse(); };
    Authenticate.prototype.getTypeName = function () { return 'Authenticate'; };
    return Authenticate;
}());
export { Authenticate };
var SetUpDemoRequest = (function () {
    function SetUpDemoRequest() {
    }
    SetUpDemoRequest.prototype.createResponse = function () { return new Object(); };
    SetUpDemoRequest.prototype.getTypeName = function () { return 'SetUpDemoRequest'; };
    return SetUpDemoRequest;
}());
export { SetUpDemoRequest };
var RedirectLogin = (function () {
    function RedirectLogin() {
    }
    RedirectLogin.prototype.createResponse = function () { return new Object(); };
    RedirectLogin.prototype.getTypeName = function () { return 'RedirectLogin'; };
    return RedirectLogin;
}());
export { RedirectLogin };
