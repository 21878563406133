import * as $ from 'jquery';
var Slick = (function () {
    function Slick() {
    }
    Slick.pageHasSlick = function () {
        return $('.car_slick').length > 0;
    };
    Slick.run = function () {
        if (Slick.pageHasSlick()) {
            Slick.initialize();
        }
    };
    Slick.initialize = function () {
        $(".car_slick").slick({
            dots: true,
            arrows: false,
            infinite: true,
            slidesToShow: 1,
            adaptiveHeight: true,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    };
    Slick.slickGoToIndex = function (i) {
        $(".car_slick").slick('slickGoTo', i);
    };
    return Slick;
}());
export { Slick };
